<template>
    <section id="aboutHeader" class="bg-about-header">
        <v-img
            dark
            :src="require('@/assets/Backgrounds/bg-aboutus-header.png')"
            class="white--text "
        >
            <v-spacer class="gap-spacer"/>
        </v-img>
        <v-container class="fill-height px-4 py-12">
                <v-responsive
                    class="d-flex align-start text-position"
                >
                    <v-img
                        :src="require('@/assets/Logo-bicolor.png')"
                        width="15%"
                        class="mx-auto mb-15 logo-bicolor"
                        data-aos="flip-left"
                        data-aos-duration="1500"
                        data-aos-delay="100"
                        data-aos-offset="300"
                    />
                    <v-container
                        class="space-title"
                        data-aos="fade-right"
                        data-aos-duration="1500"
                        data-aos-delay="100"
                    >
                        <p class="title--about--header text-center text-lg-h6 text-body-1 font-weight-light text-responsive">
                            {{ text }}
                        </p>
                    </v-container>
                </v-responsive>
            </v-container>
    </section>
</template>

<script>
export default {
    name: 'SectionAboutHeader',
    data() {
        return {
            text: "Nuestra empresa ha estado en actividad continua por más de 20 años, ejecutando principalmente trabajos de fresado de capas de pavimentos en los principales proyectos de vías urbanas, vías departamentales, vías nacionales, concesiones y aeropuertos del país; en todo este tiempo hemos fresado aproximadamente 620.000 m3 de asfalto."
        }
    },
}
</script>

<style lang="scss">
#aboutHeader {
    .gap-spacer{ margin: 40% 0; }
    @media (min-width: 1920px) {
        .text-responsive {
            margin: 0 13% 4%;
        }
    }
    @media (max-width: 800px) and (min-width: 599px) {
        .gap-spacer { margin: 70% 0; }
    }
    @media (max-width: 600px) {
        .gap-spacer { margin: 80% 0; }
        .logo-bicolor { width: 25% !important; }
    }
}
</style>